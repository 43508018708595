
import {  CacheFirst, StaleWhileRevalidate } from 'workbox-strategies';
import { Route, registerRoute  } from 'workbox-routing';

import { CacheableResponsePlugin } from 'workbox-cacheable-response';
import { ExpirationPlugin } from 'workbox-expiration';

// Cache the Google Fonts stylesheets with a stale-while-revalidate strategy.
registerRoute(new Route(
    ({ url }) => url.origin === 'https://fonts.googleapis.com',
    new StaleWhileRevalidate({
        cacheName: 'google-fonts-stylesheets',
    })
));

// Cache the underlying font files with a cache-first strategy for 1 year.
registerRoute(new Route(
    ({ url }) => url.origin === 'https://fonts.gstatic.com',
    new CacheFirst({
        cacheName: 'google-fonts-webfonts',
        plugins: [
            new CacheableResponsePlugin({
                statuses: [0, 200],
            }),
            new ExpirationPlugin({
                maxAgeSeconds: 60 * 60 * 24 * 365,
                maxEntries: 30,
            }),
        ],
    })
));

// Cache-first for images
registerRoute(new Route(
    ({ request, url }) => request.destination === 'image' || /\.(png|jpg|webp|svg)(\?.*)?/.test(url),
    new CacheFirst({
        cacheName: 'images',
        plugins: [
            new ExpirationPlugin({
                maxEntries: 60,
                maxAgeSeconds: 30 * 24 * 60 * 60, // 30 Days
            }),
        ],
    })
));


// Stale-while-revalidate for non-precached CSS and JS
registerRoute(new Route(
    ({ request, url }) => request.destination === 'script' ||
        request.destination === 'style',
    new StaleWhileRevalidate({
        cacheName: 'static-resources',
        plugins: [
            new ExpirationPlugin({
                maxEntries: 20
            }),
        ],
    })
));


// Add a progress monitor for the search index
registerRoute(new Route(
    ({  url }) => /\/searchindexparsed-.*\.json$/.test(url.pathname),
    new CacheFirst({
        cacheName: "search-index",
        plugins: [
            new ExpirationPlugin({maxEntries: 2})
        ]
    })
));

